body {
    font-family: 'Lato', sans-serif;
  }
  
.create-account-container {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  margin: 50px auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: 'Lato', sans-serif;
}

.create-account-container h1 {
  margin-top: 0;
  padding-top: 0;
  text-align: center;
}

.create-account-container p {
  text-align: center;
  margin-bottom: 20px;
}

.social-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.social-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: white;
  transition: 0.3s;
}

.social-button:hover {
  transform: scale(1.05);
}

.google { background-color: #DB4437; }
.linkedin { background-color: #0077B5; }
.apple { background-color: #000000; }

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.separator span {
  padding: 0 10px;
  color: #777;
}

.create-account-container form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.create-account-container label {
  margin-bottom: 5px;
}

.create-account-container input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.create-account-button,
.verify-account-button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: 0.3s;
}

.create-account-button:hover,
.verify-account-button:hover {
  transform: scale(1.05);
  background-color: #3395ff;
}

.resend-code-button {
  padding: 10px;
  background-color: #777;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: 0.3s;
}

.resend-code-button:hover {
  transform: scale(1.05);
  background-color: #999;
}

.trouble-link {
  text-align: center;
  margin-top: 20px;
}

.error-message {
  color: #ff0000;
  text-align: center;
  margin-bottom: 15px;
}

/* Styles for phones */
@media (max-width: 480px) {
  .create-account-container {
    max-width: 100%;
    padding: 15px;
  }

  .social-buttons {
    flex-direction: column;
  }

  input[type="email"],
  input[type="password"],
  input[type="text"],
  button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
  }

  .create-account-container h1 {
    font-size: 24px;
  }

  .create-account-container p {
    font-size: 14px;
  }

  .password-requirements {
    font-size: 12px;
  }

  .trouble-link {
    font-size: 14px;
  }
}