@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  font-family: 'Courier New', Courier, monospace;
  margin: 0;
  padding: 0;
}

.App {
  font-family: 'Lato', sans-serif;
  display: flex;
  height: 100vh;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.left-column {
  width: 250px;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ccc;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.left-column.collapsed {
  width: 50px;
}

.top-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
}

.left-column .app-title {
  color: #007bff;
  font-size: 1.2em;
  margin: 0;
  padding: 0;
}

.logo-and-name {
  display: flex;
  align-items: center;
  margin: 5px 0px;
  text-decoration: none;
}

.logo-and-name img {
  width: 30px;
  height: auto;
  margin-right: 10px;
}

.logo-and-name h1 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  text-decoration: none;
}

.collapse-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  padding-right: 15px;
  align-self: flex-end;
}

.request-history {
  flex-grow: 1;
  overflow-y: auto;
  padding: 15px;
}

.settings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;
  padding: 10px;
  margin-top: auto;
  width: 100%;
}

.settings-link {
  text-decoration: none;
}

.settings button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: none;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  padding: 5px 0;
  transition: transform 0.2s;
}

.settings button svg {
  margin-left: 5px;
  margin-right: 10px;
  font-size: 20px;
}

.settings button:hover {
  transform: scale(1.1);
}

.settings-container {
  position: relative;
}

.settings-popup {
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px;
}

.nav-button {
  position: relative;
}

.nav-button[hover-tooltip]:hover::after {
  content: attr(hover-tooltip);
  position: absolute;
  background: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  bottom: 20%;
  left: 80%;
  transform: translateY(-50%);
}

.main-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f5f5f5;
}

.input-sections {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.input-resume,
.input-job-post {
  width: 48%;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.input-resume h3, 
.input-job-post h3 {
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 10px;
}

.resume-input-container {
  margin-bottom: 10px;
}

.file-input-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.file-input-wrapper input[type="file"] {
  width: 100%;
  padding: 6px 30px 6px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.remove-file {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #8B0000;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  padding: 5px;
}

/* Hide the default file input text */
.file-input-wrapper input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
  width: 0;
}

.file-input-wrapper input[type="file"]::before {
  content: 'Choose PDF';
  display: inline-block;
  background: #f0f0f0;
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.file-input-wrapper input[type="file"]:hover::before {
  border-color: black;
}

.file-input-wrapper input[type="file"]:active::before {
  background: #e0e0e0;
}

.input-resume textarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  box-sizing: border-box;
  resize: vertical;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.job-post-input-container {
  position: relative;
  margin-bottom: 10px;
}

.job-post-input-container input[type="url"] {
  width: 100%;
  padding: 10px;
  padding-right: 30px;
  box-sizing: border-box;
}

.remove-link {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #8B0000;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  padding: 5px;
}

.input-job-post textarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  box-sizing: border-box;
  resize: vertical;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input:disabled {
  background-color: #f0f0f0;
  color: #a0a0a0;
  cursor: not-allowed;
}

.or-separator {
  text-align: center;
  color: #666;
  margin: 10px 0;
}

.control-panel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 10px;
}

.document-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f9f9f9;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-top: 10px;
  text-align: left !important;
  position: relative;
}

.evaluation-container {
  padding: 10px 20px;
  flex-shrink: 0;
  text-align: left !important;
}

.no-evaluation {
  text-align: center;
  color: #888;
}

.processing-request {
  text-align: center;
}

.evaluation-container h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;
}

.evaluation-result-wrapper {
  text-align: left !important;
}

.evaluation-result {
  text-align: left !important;
}

.evaluation-result-wrapper p {
  text-align: left !important; /* Force left alignment */
}

.evaluation-result strong {
  font-weight: bold;
}

.evaluation-result ul, .evaluation-result ol {
  margin-left: 20px;
}

.evaluation-result li {
  margin-bottom: 5px;
}

.processing-request {
  text-align: center;
  color: #007bff;
}

.ellipsis {
  display: inline-block;
  vertical-align: bottom;
  line-height: 1;
  width: 20px;
  height: 20px;
  position: relative;
}

.ellipsis::after {
  content: '...';
  position: absolute;
  left: 0;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis 2s infinite;
  white-space: nowrap;
}

@keyframes ellipsis {
  0% { content: ''; }
  25% { content: '.'; }
  50% { content: '..'; }
  75% { content: '...'; }
  100% { content: ''; }
}

.optimization-container {
  margin-top: 20px;
  padding: 10px 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.optimization-container h2 {
  margin-bottom: 10px;
}

.optimization-container .quill {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.optimization-container .ql-container {
  font-size: 16px;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.optimization-container .ql-editor {
  text-align: left !important;
  padding: 12px 15px;
  overflow-y: auto;
}

.optimization-container .ql-editor p {
  text-align: left !important;
  margin-left: 0 !important;
  text-indent: 0 !important;
}

.optimization-container .ql-editor ul,
.optimization-container .ql-editor ol {
  padding-left: 1em;
  margin-left: 0;
}

.optimization-container .ql-editor ol,
.optimization-container .ql-editor ul,
.optimization-container .ql-editor pre,
.optimization-container .ql-editor blockquote {
  margin-bottom: 0.5em;
}

.optimization-container .ql-editor h1,
.optimization-container .ql-editor h2,
.optimization-container .ql-editor h3 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.optimization-container .ql-editor ul,
.optimization-container .ql-editor ol {
  padding-left: 1.5em;
}

.optimization-container .ql-editor li {
  margin-bottom: 0.5em;
}

.optimization-container .ql-editor blockquote {
  border-left: 4px solid #ccc;
  padding-left: 16px;
  margin-left: 0;
}

.ql-toolbar {
  border-radius: 8px 8px 0 0;
}

.floating-feedback {
  position: fixed;
  bottom: 30px;
  right: 50px;
  background-color: #007bff; /* Adjust this to match your button blue color */
  color: white;
  padding: 10px 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.floating-feedback:hover {
  transform: scale(1.03);
  background-color: #3395ff;
}

.floating-feedback a {
  text-decoration: none;
  color: white;
}

.floating-feedback .logo-container {
  background-color: white;
  border-radius: 50%;
  padding: 1px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.floating-feedback .feedback-logo {
  width: 32px;
  height: 32px;
}

.floating-feedback span {
  font-weight: bold;
}