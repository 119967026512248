.auth-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.auth-modal {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 90%; /* Changed to percentage for better responsiveness */
  max-width: 1000px; /* Added max-width */
  height: auto; /* Changed from fixed height to auto */
  max-height: 80vh; /* Added max-height */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-top: 15px; /* Reduced top padding */
}

.auth-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px; /* Removed top padding */
  position: relative; /* Added for absolute positioning of close button */
}

.auth-tabs {
  display: flex;
  justify-content: center;
  width: 100%; /* Ensure tabs take full width */
  margin-bottom: 20px;
}

.auth-tab {
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px; /* Reduced font size */
  color: #333;
}

.auth-tab.active {
  border-bottom: 2px solid #007bff;
  color: #007bff;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 0;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
}

.auth-title {
  text-align: center;
  margin-top: 0;
}

.subscribe-content,
.login-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.subscription-tiers {
  display: flex;
  justify-content: space-between;
  gap: 15px; /* Reduced gap */
  margin-top: 15px; /* Reduced top margin */
  flex-grow: 1;
}

.subscription-tier {
  flex: 1;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
transition: transform 0.3s ease;
}

.subscription-tier:hover {
  transform: translateY(-5px);
}

.tier-label {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ffd700;
  color: #333;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
}

.price {
  font-size: 36px;
  font-weight: bold;
  margin: 10px 0;
}

.period {
  font-size: 18px;
  font-weight: normal;
}

.total-price {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.tier-features {
  text-align: left;
  padding: 0rem 1rem;
  flex-grow: 1; /* Added to allow features list to grow */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Aligns content to the top */
}

.subscribe-button,
.email-login-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s ease;
  margin-top: auto; /* Added to push button to the bottom */
}

.subscribe-button:hover,
.email-login-button:hover,
.password-reset-button:hover {
  transform: scale(1.05);
  background-color: #3395ff;
}

.login-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
}

.social-logins {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.social-login-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: white;
  transition: transform 0.3s;
}

.social-login-button:hover {
  transform: scale(1.05);
}

.google { background-color: #DB4437; }
.linkedin { background-color: #0077B5; }
.apple { background-color: #000000; }

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.separator span {
  padding: 0 10px;
  color: #777;
}

.email-login {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.email-login input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.logging-in-message {
  text-align: center;
  margin-top: 20px;
  font-size: 1rem;
  font-weight: bold;
}

.password-reset-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.password-reset-form input[disabled] {
  background-color: #f0f0f0;
  color: #888;
}

.password-reset-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: 0.3s ease;
}

.password-reset-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.auth-switch {
  text-align: center;
  margin-top: 20px;
  font-size: 1rem;
}

@media (max-width: 480px) {
  .auth-modal {
    width: calc(100% - 4rem); /* Add 2rem margin on each side */
    padding: 20px 15px;
    margin: 0 2rem; /* Explicitly set horizontal margins */
  }

  .auth-header {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 5px;
    padding-top: 30px; 
  }

  .close-button {
    position: absolute;
    right: 0px;
    font-size: 20px;
  }
  
  .subscription-tiers {
    flex-direction: column;
  }
}