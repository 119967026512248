@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  font-family: 'Courier New', Courier, monospace;
}

.home {
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
}

.mobile-menu {
  display: none;
}

.mobile-menu-icon {
  display: none;
}

.menu-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
  box-sizing: border-box; /* Add this to include padding in width calculation */
}

.logo-container {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
}

.logo-container:hover {
  cursor: pointer;
}

.logo {
  height: 30px;
  margin-right: 10px;
  transition: 0.1s ease;
}

.logo:hover {
  transform: scale(1.05);
}

.app-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #007bff;
  transition: 0.1s ease;
}

.app-name:hover {
  transform: scale(1.02);
  color: #3395ff;
  text-decoration: none;
}

.nav-links {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
  margin: 0 1rem;
  padding: 0.5rem 0.5rem;
  transition: transform 0.3s ease;
}

.nav-links a:hover {
  transform: scale(1.1);
}

.login-container {
    display: flex;
    align-items: center;
  }

.login-button {
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  display: inline-block;
  padding: 15px 30px;
  margin-right: 0.5rem;
  transition: 0.3s ease;
}

.login-button:hover {
  transform: scale(1.05);
  background-color: #3395ff;
  text-decoration: none;
}

.home-content {
  position: relative;
  padding-top: 8rem;
  padding-bottom: 1rem;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.title-logo {
  width: 80px;
  height: auto;
  margin-right: 10px;
  transition: 0.2s ease;
}

.title-logo:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.logo-link {
  display: flex;
  align-items: center;
}

.app-title {
  color: #000;
  font-size: 4rem;
  font-weight: 800;
  margin: 0;
  transition: 0.2s ease;
}

.app-title:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.app-title-link {
  text-decoration: none;
}

.app-subtitle-link {
  text-decoration: none;
}

.app-subtitle {
  font-size: 2rem;
  font-weight: normal;
  color: #007bff;
  text-align: center;
  text-decoration: none;
  margin: 0;
  padding: 0;
  transition: 0.1s ease;
}

.app-subtitle:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.launch-button {
  margin: 30px;
  padding: 15px 20px;
  font-size: 1.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  display: inline-block;
  transition: 0.3s ease;
}

.launch-button:hover {
  transform: scale(1.05);
  background-color: #3395ff;
  text-decoration: none;
}

.video-container {
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
}

.demo-video {
  width: 100%;
  height: auto;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/*
.image-carousel {
  position: relative;
  width: 600px;
  height: 400px;
  margin: 15px auto;
  overflow: hidden;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-carousel img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-carousel .placeholder-text {
  color: #333;
  font-size: 18px;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}
*/
.scroll-indicator {
  position: absolute;
  bottom: -42px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.chevron-down {
  color: rgba(128, 128, 128, 0.5); /* Faint gray color */
  font-size: 24px; /* Adjust size as needed */
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(10px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 50px;
  right: 75px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  z-index: 1000;
  transition: 0.2s ease;
}

.scroll-to-top:hover {
  transform: scale(1.05);
  opacity: 0.8;
}

.scroll-to-top.inactive {
  background-color: #cccccc;
  cursor: default;
}

.scroll-to-top.inactive:hover {
  opacity: 1;
}

.testimonials,
.how-it-works,
.features,
.pricing,
.blog-preview,
.help-section {
  padding: 4rem 0;
  margin: 4rem 0;  
}

/* Features */
.features {
  background-color: #F0F2F7  ;
  padding: 4rem 4rem;
  box-shadow: 8px 0px 8px rgba(0, 0, 0, 0.1);
}

.feature-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  margin: 4rem 0;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  gap: 6rem;
  flex-wrap: wrap;
  max-width: 1000px;
}

.feature-image-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  max-width: 400px;
  text-align: right;
}

.feature-image-link {
  display: block;
  width: 100%;
  transition: transform 0.3s ease;
}

.feature-image-link:hover {
  transform: scale(1.05);
}

.feature-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  border: 2px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feature-image-source {
  font-size: 0.7rem;
  font-weight: normal;
  color: #666;
  text-decoration: none;
  margin-top: 0.5rem;
}

.feature-image-source:hover {
  text-decoration: underline;
}

.feature-text {
  flex: 1;
  text-align: left; 
}

.feature-text-link {
  text-decoration: none;
}

.feature-text h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  text-align: left;
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
  margin: 0;
  padding: 0;
  transition: 0.2s ease;
}

.feature-text h1:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.feature-text h2 {
  font-size: 1.5rem;
  text-align: left;
  font-weight: normal;
  color: #000;
  text-decoration: none;
  margin: 1rem 0;
  padding: 0;
}

/* How It Works */
.how-it-works {
  padding: 2rem 0;
  text-align: center;
}

.how-it-works h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
  margin: 2rem 0;
  padding: 0;
  transition: 0.2s ease;
}

.how-it-works h1:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.how-it-works-link {
  text-decoration: none;
}

.how-it-works-subtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 4rem auto;
  max-width: 800px;
}

.how-it-works h2 {
  font-size: 2rem;
  font-weight: normal;
  color: #000;
  margin: 1rem auto;
  padding: 0rem 1rem;
}

.how-it-works h3 {
  font-size: 1.5rem;
  font-weight: normal;
  color: #000;
  margin: 1rem auto;
  padding: 0rem 1rem;
}

.app-screenshot-container {
  position: relative;
  max-width: 1000px;
  margin: 2rem auto;
  padding: 1rem;
}

.app-screenshot {
  width: 100%;
  height: auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tooltip-trigger {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #FFC000;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.1s ease;
}

.tooltip-trigger::after {
  content: '?';
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tooltip-content {
  position: absolute;
  background-color: white;
  border: 1px solid #007bff;
  border-radius: 4px;
  padding: 0.5rem;
  width: 200px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 10;
}

.tooltip-trigger:hover .tooltip-content,
.tooltip-trigger.active .tooltip-content {
  opacity: 1;
  visibility: visible;
}

/* Pricing */
.pricing {
  padding: 4rem 2rem;
  background-color: #F0F2F7  ;
  box-shadow: 8px 0px 8px rgba(0, 0, 0, 0.1);
}

.pricing h2 {
  text-align: center;
  margin-top: 0rem;
  margin-bottom: 1rem;
}

.pricing-tiers {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  padding: 1rem;
}

.pricing-tier {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 300px;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: transform 0.3s ease;
}

.pricing-tier:hover {
  transform: translateY(-5px);
}

.tier-label {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ffd700;
  color: #333;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: bold;
}

.tier-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.pricing-tier h3 {
  margin-bottom: 1rem;
}

.price {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.period {
  font-size: 1rem;
  font-weight: normal;
}

.total-price {
  color: #6c757d;
  margin-bottom: 1rem;
}

.tier-features {
  margin-bottom: 1rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.tier-features p {
  margin-bottom: 0.5rem;
  text-align: left;
  padding: 0rem 1rem;
}

.subscribe-button {
  display: block;
  width: calc(100% - 2rem);
  padding: 0.75rem 1rem;
  margin: 1rem auto 0;
  background-color: #007bff;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 10px;
  transition: 0.2s ease;
}

.subscribe-button:hover {
  background-color: #0056b3;
}

/* Top Voices */
.top-voices {
  padding: 2rem;
  margin: 2rem auto;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.top-voices h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
  margin: 0 auto;
  padding: 0 auto;
  transition: 0.2s ease;
}

.top-voices h2 {
  font-size: 2rem;
  font-weight: normal;
  color: #000;
  text-decoration: none;
  margin: 1rem 0;
  padding: 0;
}

.linkedin-posts-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6rem;
  max-width: 1000px;
  margin: 2rem auto;
  padding: 0rem;
}

.linkedin-post {
  width: 100%;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.linkedin-post-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  width: 100%;
}

.linkedin-profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
  flex-shrink: 0;
}

.linkedin-post-author {
  display: flex;
  flex-direction: column;
}

.linkedin-post p {
  margin-bottom: 0.5rem;
  width: 100%;
}

.linkedin-post .author {
  font-weight: bold;
  text-align: left;
}

.linkedin-post .position {
  text-align: left;
  font-style: italic;
  color: #666;
}

.linkedin-post .title {
  font-weight: bold;
  text-align: left;
}

.linkedin-post .content {
  font-weight: normal;
  text-align: left;
}

.linkedin-link {
  display: inline-block;
  margin-top: 1rem;
  color: #0077B5;
  text-decoration: none;
}

.linkedin-link:hover {
  text-decoration: underline;
}

.cta {
  text-align: center;
  padding: 40px 20px;
}

/* Blog Preview */
.blog-preview {
  padding: 4rem 4rem;
  background-color: #F9F6F2;
  box-shadow: 8px 0px 8px rgba(0, 0, 0, 0.1);
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  max-width: 1200px;
  align-items: center;
  margin: 4rem auto;
  gap: 20px;
}

.blog-post {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* FAQ */
.faq-section {
  background-color: #F0F2F7 ;
  box-shadow: 8px 0px 8px rgba(0, 0, 0, 0.1);
  padding: 4rem;
  margin: 0 auto;
}

.faq-section h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
  margin: 0 auto;
  padding: 0 auto;
  text-align: center;
}

.faq-list {
  max-width: 800px;
  margin: 0 auto;
}

.faq-item {
  margin-bottom: 1.5rem;
  text-align: left;
}

.faq-question {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.faq-answer {
  margin-left: 2rem;
  font-weight: normal;
  line-height: 1.5;
}

/* Help */
.help-section {
  padding: 4rem;
  margin: 0 auto;
}

/* Footer */
.site-footer {
  background-color: #333;
  color: white;
  padding: 1rem 4rem;
  text-align: center;
}

.footer-links {
  margin-bottom: 1rem;
}

.footer-links a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.copyright {
  font-size: 0.7em;
  margin: 0;
  padding: 0;
}

/* Mobile styles */
@media (max-width: 480px) {
  .menu-bar {
    position: relative;
  }

  .menu-bar .nav-links,
  .menu-bar .login-container {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .mobile-menu {
    display: none;
    position: absolute;
    top: 50px;
    right: 10px;
    background-color: #ffffff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    padding: 1.5rem;
    flex-direction: column;
    z-index: 1001;
    height: auto;
    min-height: 200px;
  }

  .mobile-menu.active {
    display: flex;
    height: 50%;
    width: 25%;
  }

  .mobile-menu-nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .mobile-menu-nav-links a {
    display:block;
    margin: 0.5rem 0;
    font-size: 1rem;
    text-decoration: none;
    transition: 0.2s ease;
    color: #333;
  }

  .mobile-menu-nav-links a:hover {
    transform: scale(1.05);
  }

  .mobile-menu-login-container {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    border-top: 1px solid #e0e0e0;
  }

  .mobile-menu-login-button {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: 0.2s ease;
  }

  .mobile-menu-login-button:hover {
    transform: scale(1.05);
  }

  /* Stack main content */
  .home-content {
    padding-top: 4rem;
    align-items: center;
  }

  .title-container {
    flex-direction: column;
  }

  .app-title {
    font-size: 4rem;
  }

  .app-subtitle {
    font-size: 2rem;
  }

  .video-container {
    width: auto;
    margin: 1rem;
  }

  .testimonials,
  .pricing,
  .features,
  .blog-preview {
    padding: 2rem auto;
  }

  .linkedin-posts-container {
    grid-template-columns: 1fr;
  }

  .pricing-tiers {
    flex-direction: column;
    align-items: center;
  }

  .pricing-tier {
    width: 100%;
    max-width: 300px;
  }

  .feature-item {
    flex-direction: column;
  }

  .feature-image-link {
    width: 100%;
  }

  .blog-grid {
    grid-template-columns: 1fr;
  }

  .scroll-to-top {
    position: fixed;
    bottom: 25px;
    right: 25px;
  }

  .site-footer {
    padding: 1rem;
  }
}