.privacy-page {
    font-family: 'Arial', sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.privacy-page h1,
.privacy-page h2,
.privacy-page p,
.privacy-page ul,
.privacy-page li {
    text-align: left;
}

.privacy-page ul {
    padding-left: 20px;
}

.back-to-home {
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;
    color: #007bff;
}

.back-to-home:hover {
    text-decoration: underline;
}