.optimize-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.optimize-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.optimize-button {
  margin: 20px;
  padding: 15px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.optimize-button-title {
  display: block;
  font-size: 16px;
  font-weight: bold;
}

.optimize-button-subtitle {
  display: block;
  font-size: 10px;
}

.optimize-button:hover {
  background-color: #3395ff;
  cursor: pointer;
}

.optimize-button:disabled {
  background-color: #004a99;
  cursor: not-allowed;
  animation: none;
}

.optimize-button.enabled {
  animation: glowPulse 2s infinite;
}

@keyframes glowPulse {
  0% {
    box-shadow: 0 0 5px rgba(255, 215, 0, 0.7);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 215, 0, 1);
  }
  100% {
    box-shadow: 0 0 5px rgba(255, 215, 0, 0.7);
  }
}

.evaluate-button {
  margin: 20px;
  padding: 15px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.evaluate-button-title {
  display: block;
  font-size: 16px;
  font-weight: bold;
}

.evaluate-button-subtitle {
  display: block;
  font-size: 10px;
}

.evaluate-button:hover {
  background-color: #3395ff;
  cursor: pointer;
}

.evaluate-button:disabled {
  background-color: #004a99;
  cursor: not-allowed;
  animation: none;
}

.evaluate-button.enabled {
  animation: glowPulse 2s infinite;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.optimize-options {
  display: flex;
  align-items: center;
  gap: 20px;
}


.optimize-checkbox {
  margin: 0;
  cursor: not-allowed;
  opacity: 0.7;
}

.optimize-checkbox:disabled {
  cursor: not-allowed;
}

.optimize-checkbox:disabled + label {
  opacity: 0.7;
}

.optimize-dropdown {
  padding: 5px;
  margin: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: not-allowed;
  opacity: 0.7;
  margin-left: 10px;
  align-items: center;
}

.optimize-dropdown:disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.optimize-dropdown {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: not-allowed;
  opacity: 0.7;
}

.optimize-dropdown:disabled {
  background-color: #f0f0f0;
}

.slider-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 250px;
  margin: auto 20px;
}

.slider-container label {
  font-weight: bold;
  color: #777;
  margin-bottom: 0px;
}

.slider-container input[type="range"] {
  width: 100%;
  opacity: 0.7;
  cursor: not-allowed;
  margin-top: 0px;
}

.regenerate-button {
  margin: 20px;
  padding: 15px 25px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: not-allowed;
}

.regenerate-button:hover {
  background-color: #3395ff;
}

.regenerate-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Add this to style the tooltip */
[hover-tooltip] {
  position: relative;
  cursor: help;
}

[hover-tooltip]:hover::after {
  content: attr(hover-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background-color: #333;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 1;
}