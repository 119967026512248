.settings-popup {
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
  }
  
  .settings-popup h3 {
    margin-top: 10px;
  }
  
  .settings-popup ul {
    list-style-type: none;
    padding: 0;
  }
  
  .settings-popup li {
    padding-top: 10px; /* Added padding to create space between items */
  }
  
  .settings-popup li:last-child {
    margin-bottom: 0; /* Remove margin from the last item */
    padding-bottom: 0; /* Remove padding from the last item */
    border-bottom: none; /* Remove border from the last item */
  }
  
  .settings-popup select,
  .settings-popup input[type="checkbox"] {
    margin-left: 10px;
  }
  
  /* Styles for disabled elements */
  .settings-popup select:disabled,
  .settings-popup input[type="checkbox"]:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }